import React from "react";
import {
  FoodIcon,
  HealthcareIcon,
  HouseIcon,
  MiscellaneousIcon,
  TransportationIcon,
  UtilitiesIcon,
} from "../../icons";

export const config = [
  {
    icon: <HouseIcon />,
    name: "Housing",
  },
  {
    icon: <UtilitiesIcon />,
    name: "Utilities",
  },
  {
    icon: <TransportationIcon />,
    name: "Transportation",
  },
  {
    icon: <FoodIcon />,
    name: "Food",
  },
  {
    icon: <MiscellaneousIcon />,
    name: "Miscellaneous",
  },
  {
    icon: <HealthcareIcon />,
    name: "Healthcare",
  },
];
