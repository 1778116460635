import React from "react";
import "./style.css";
export const Button = ({ onClick, text }) => {
  return (
    <div className={'c-button-box'}>
      <button type="submit" className={"c-button"} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};
