import React from "react";

export const HouseIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.6663 49.2916H4.33301C3.44467 49.2916 2.70801 48.555 2.70801 47.6666C2.70801 46.7783 3.44467 46.0416 4.33301 46.0416H47.6663C48.5547 46.0416 49.2913 46.7783 49.2913 47.6666C49.2913 48.555 48.5547 49.2916 47.6663 49.2916Z"
        fill="white"
      />
      <path
        d="M8.0166 47.6645H4.7666L4.87493 21.5994C4.87493 19.7578 5.71993 18.0461 7.1716 16.9194L22.3383 5.11111C24.4833 3.44278 27.4949 3.44278 29.6616 5.11111L44.8283 16.8978C46.2583 18.0244 47.1249 19.7794 47.1249 21.5994V47.6645H43.8749V21.6211C43.8749 20.7978 43.4849 19.9961 42.8349 19.4761L27.6683 7.68945C27.1904 7.3205 26.6037 7.12036 25.9999 7.12036C25.3962 7.12036 24.8095 7.3205 24.3316 7.68945L9.16493 19.4978C8.51493 19.9961 8.12493 20.7978 8.12493 21.6211L8.0166 47.6645Z"
        fill="white"
      />
      <path
        d="M31.4163 49.2916H20.583C19.6947 49.2916 18.958 48.555 18.958 47.6666V40.0833C18.958 37.3966 21.1463 35.2083 23.833 35.2083H28.1663C30.853 35.2083 33.0413 37.3966 33.0413 40.0833V47.6666C33.0413 48.555 32.3047 49.2916 31.4163 49.2916ZM22.208 46.0416H29.7913V40.0833C29.7913 39.195 29.0547 38.4583 28.1663 38.4583H23.833C22.9447 38.4583 22.208 39.195 22.208 40.0833V46.0416ZM20.583 31.4166H16.2497C14.1697 31.4166 12.458 29.705 12.458 27.625V24.375C12.458 22.295 14.1697 20.5833 16.2497 20.5833H20.583C22.663 20.5833 24.3747 22.295 24.3747 24.375V27.625C24.3747 29.705 22.663 31.4166 20.583 31.4166ZM16.2497 23.8333C15.9463 23.8333 15.708 24.0716 15.708 24.375V27.625C15.708 27.9283 15.9463 28.1666 16.2497 28.1666H20.583C20.8863 28.1666 21.1247 27.9283 21.1247 27.625V24.375C21.1247 24.0716 20.8863 23.8333 20.583 23.8333H16.2497ZM35.7497 31.4166H31.4163C29.3363 31.4166 27.6247 29.705 27.6247 27.625V24.375C27.6247 22.295 29.3363 20.5833 31.4163 20.5833H35.7497C37.8297 20.5833 39.5413 22.295 39.5413 24.375V27.625C39.5413 29.705 37.8297 31.4166 35.7497 31.4166ZM31.4163 23.8333C31.113 23.8333 30.8747 24.0716 30.8747 24.375V27.625C30.8747 27.9283 31.113 28.1666 31.4163 28.1666H35.7497C36.053 28.1666 36.2913 27.9283 36.2913 27.625V24.375C36.2913 24.0716 36.053 23.8333 35.7497 23.8333H31.4163ZM41.1663 16.7916C40.278 16.7916 39.5413 16.0766 39.5413 15.1883L39.498 10.2916H31.5897C30.7013 10.2916 29.9647 9.55496 29.9647 8.66663C29.9647 7.77829 30.7013 7.04163 31.5897 7.04163H41.123C42.0113 7.04163 42.748 7.75663 42.748 8.64496L42.813 15.145C42.7913 16.055 42.0763 16.7916 41.1663 16.7916Z"
        fill="white"
      />
    </svg>
  );
};
