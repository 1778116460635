import "./App.css";
import { Layout } from "./components";
import { Home } from "./pages/home";

function App() {
    return (
        <div className="App">
            <Layout>
                <Home />
            </Layout>
        </div>
    );
}

export default App;
