import React from "react";

import { Button, Input, Select } from "../../../../components";

import "./style.css";
export const SearchBox = ({
  movingToOptions,
  handleSelectFromToData,
  inputValue,
  handleInput,
  onSubmit,
  loadingFrom,
  errors,
}) => {
  return (
    <form className={"b-search-box"} onSubmit={onSubmit}>
      <Input
        label={"MOVING TO"}
        disabled={true}
        value={"Redding, CA"}
        placeholder={"$50000"}
        error={errors?.to}
      />
      <Select
        label={"FROM (CURRENT CITY)"}
        options={movingToOptions}
        onSelectChange={handleSelectFromToData}
        error={errors?.from}
        loading={loadingFrom}
      />
      <Input
        label={"Pre-tax household income"}
        value={inputValue}
        onChange={handleInput}
        placeholder={"$50000"}
        special={'currency'}
        error={errors?.income}
      />
      <Button text={"CALCULATE"} type={"submit"} onClick={onSubmit} />
      <span>
        <div>Where does the data come from?</div>
        Core cost of living data is from The Cost of Living Index - COLI from
        the Council for Community and Economic Research. Price collection by
        city is conducted in strict conformance with standard specifications at
        specified times.
      </span>
    </form>
  );
};
