import { useEffect, useState } from "react";

export const useApiData = ({ url, params, autoFetch = true }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(autoFetch);

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryString = new URLSearchParams(params).toString();
      const apiUrl = `${process.env.REACT_APP_BASE_URL}${url}?licenseeGuid=${process.env.REACT_APP_LICENSEEGUID}&${queryString}`;

      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error);
    } finally {
      setTrigger(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataOnTrigger = async () => {
      if (trigger) {
        await fetchData();
      }
    };

    fetchDataOnTrigger();
  }, [trigger]);

  const executeFetch = () => {
    setTrigger(true);
  };

  return { executeFetch, data, loading, error };
};
