import React from "react";
import "./style.css";
import { StartIcon } from "../../../../icons";
export const SpendingPowerBox = ({ percent, state, price }) => {
  if (!percent) {
    return null;
  }
  if (isNaN(percent)) {
    return (
      <span className={"spending-power-cost"}>
        Wrong PRE-TAX HOUSEHOLD INCOME
      </span>
    );
  }
  return (
    <div>
      {percent < 0 ? (
        <div className={"spending-power-title"}>
          <StartIcon /> WOOHOO! INCREASED SPENDING POWER!
        </div>
      ) : null}
      <div className={"spending-power-desc"}>
        The cost of living is{" "}
        <span className={"spending-power-cost"}>
          {Math.abs(percent).toFixed(1)}% {percent > 0 ? "higher" : "lower"} in{" "}
          {state}
        </span>
        . To maintain your standard of living you’ll need a household income of{" "}
        {price}
      </div>
    </div>
  );
};
