import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import {getSortedOptions} from "../../utils";

export const Select = ({ label, options, onSelectChange, error, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSearchText(option.name);
    if (onSelectChange) {
      onSelectChange(option);
    }
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setIsOpen(true);
  };

  const sortedOptions = getSortedOptions(options, searchText)

  return (
    <div
      className={`dropdown-container ${isOpen ? "open" : ""}`}
      ref={dropdownRef}
    >
      <label className={"dropdown-label"}>{label}</label>
      <div className={"dropdown-header"} onClick={toggleDropdown}>
        <input
          type="text"
          placeholder="Select City"
          value={searchText}
          className={`c-input ${error ? "error" : ""}`}
          onChange={handleInputChange}
          ref={inputRef}
        />
        <span className={`arrow ${isOpen ? "up" : "down"}`}></span>
      </div>
      {isOpen ? (
        <div className={`dropdown-box`}>
          <ul className={"dropdown-list"}>
            {sortedOptions?.length ? (
                sortedOptions?.map((option) => (
                <li
                  key={option.id}
                  data-id={option.id}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </li>
              ))
            ) : (
              <div className={"dropdown-empty"}>
                {loading ? "loading" : "Nothing was found"}
              </div>
            )}
          </ul>
        </div>
      ) : null}
      <span className={`dropdown-helper ${error ? "error" : ""}`}>
        {error ? error : ""}
      </span>
    </div>
  );
};
