import React, { createRef, useRef, useState } from "react";

import { ArrowIcon } from "../../../../icons";
import "./styles.css";
export const DetailsBox = ({ details, names, children }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentEls = useRef(details.map(() => createRef()));
  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className={"details-wrapper"}>
      {children}
      {details?.length > 1 &&
        details?.map((el, index) => (
          <div key={index} className={"details-box"}>
            <div
              className={"details-header"}
              onClick={(e) => {
                e.stopPropagation();
                handleAccordionClick(index);
              }}
            >
              <div className={"details-icon-box"}>
                {el?.config?.icon} <span>{el?.config?.name}</span>
              </div>
              {el?.percentage?.map((item, i) => (
                <div className="details-percentage-box" key={i}>
                  <div
                    className={`details-percentage ${item[0]?.DifferenceType}`}
                  >
                    {parseFloat(item[0]?.PercentageDifference).toFixed(1)}%
                    {/* Convert to number and round to one decimal place */}
                    <span>
                      {item[0]?.DifferenceType === "more" ? "HIGHER" : "LOWER"}
                    </span>
                  </div>
                  <ArrowIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAccordionClick(index);
                    }}
                    className={`details-percentage-icon ${
                      activeIndex === index ? "up" : "down"
                    }`}
                  />
                </div>
              ))}
            </div>
            <div
              className={`details-content ${
                activeIndex === index ? "open" : ""
              }`}
              ref={contentEls.current[index]}
            >
              {names[0] && (
                <div className={"details-content-head"}>
                  <div></div>
                  <div className={"details-info-item upper"}>
                    {names[1]?.name}
                  </div>
                  <div className={"details-info-item upper"}>
                    {names[0]?.name}
                  </div>
                </div>
              )}
              {el?.data.map((item, i) => (
                <div key={i} className={"details-content-head"}>
                  <div className="details-info-item">{item.Category_Name}</div>
                  <div className="details-info-item">{item.FromPlaceCost}</div>
                  <div className="details-info-item">{item.ToPlaceCost}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};
