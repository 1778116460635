import React from "react";

export const HealthcareIcon = () => {
  return (
    <svg
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9997 46.0104C25.328 46.0104 24.678 45.9254 24.1363 45.7342C15.8597 42.9504 2.70801 33.0692 2.70801 18.4704C2.70801 11.0329 8.83967 4.99792 16.3797 4.99792C20.0413 4.99792 23.4647 6.40042 25.9997 8.90792C27.2581 7.66238 28.757 6.67514 30.4086 6.00382C32.0603 5.3325 33.8318 4.99055 35.6197 4.99792C43.1597 4.99792 49.2913 11.0542 49.2913 18.4704C49.2913 33.0904 36.1397 42.9504 27.863 45.7342C27.3213 45.9254 26.6713 46.0104 25.9997 46.0104ZM16.3797 8.18542C10.638 8.18542 5.95801 12.7967 5.95801 18.4704C5.95801 32.9842 20.193 41.0592 25.198 42.7379C25.588 42.8654 26.433 42.8654 26.823 42.7379C31.8063 41.0592 46.063 33.0054 46.063 18.4704C46.063 12.7967 41.383 8.18542 35.6413 8.18542C32.348 8.18542 29.293 9.69417 27.3213 12.3079C26.7147 13.1154 25.328 13.1154 24.7213 12.3079C23.7586 11.0267 22.5017 9.98587 21.0525 9.26966C19.6033 8.55345 18.0025 8.18202 16.3797 8.18542Z"
        fill="white"
      />
    </svg>
  );
};
