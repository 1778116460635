import React from "react";

export const UtilitiesIcon = () => {
  return (
    <svg
      width="54"
      height="53"
      viewBox="0 0 54 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4773 50.2396C22.0273 50.2396 21.6673 50.1513 21.3973 50.0409C20.4973 49.7096 18.9673 48.6276 18.9673 45.2046V30.9609H13.7023C10.6873 30.9609 9.60731 29.5696 9.22481 28.7526C8.84231 27.9134 8.50481 26.213 10.4848 23.9826L27.5173 4.99089C29.8123 2.42923 31.6798 2.60589 32.5798 2.93714C33.4798 3.26839 35.0098 4.35048 35.0098 7.77339V22.0171H40.2748C43.2898 22.0171 44.3698 23.4084 44.7523 24.2255C45.1348 25.0646 45.4723 26.7651 43.4923 28.9955L26.4598 47.9871C24.8623 49.7759 23.4673 50.2396 22.4773 50.2396ZM31.3423 6.05089C31.2748 6.13923 30.8023 6.36006 30.0598 7.19923L13.0273 26.1909C12.3973 26.8976 12.3073 27.3392 12.3073 27.4276C12.3523 27.4496 12.7573 27.6705 13.7023 27.6705H20.6548C21.5773 27.6705 22.3423 28.4213 22.3423 29.3267V45.2267C22.3423 46.3309 22.5448 46.8167 22.6348 46.9492C22.7023 46.8609 23.1748 46.6401 23.9173 45.8009L40.9498 26.8092C41.5798 26.1026 41.6698 25.6609 41.6698 25.5726C41.6248 25.5505 41.2198 25.3296 40.2748 25.3296H33.3223C32.3998 25.3296 31.6348 24.5788 31.6348 23.6734V7.77339C31.6573 6.66923 31.4323 6.20548 31.3423 6.05089Z"
        fill="white"
      />
    </svg>
  );
};
