import React, { useEffect, useState } from "react";

import { api } from "../../constant";
import {
  calculateCostOfLivingPercentage,
  mapToOptions,
  useApiData,
  useFetchPlaces,
  UseFilterByCategory,
  UseMoveItemInArray,
} from "../../utils";

import { DetailsBox, SearchBox } from "./components";
import { config } from "./config";
import { Spinner } from "../../components";
import { SpendingPowerBox } from "./components/spendingPowerBox";

export const Home = () => {
  const [calculate, setCalculate] = useState(false);
  const [dataValues, setDataValues] = useState({
    movingTo: { id: 589, name: "REDDING, CA" },
    fromTo: [],
  });
  const [inputValue, setInputValue] = useState("");
  const [details, setDetails] = useState([]);
  const [averageList, setAverageList] = useState([]);
  const [expenditureList, setExpenditureList] = useState([]);
  const [errors, setErrors] = useState({
    from: "",
    income: "",
  });
  const [names, setNames] = useState([])
  const [comparativeSalary, setComparativeSalary] = useState();
  const [costOfLivingPercentage, setCostOfLivingPercentage] = useState(0);

  const validateForm = () => {
    const newErrors = {};
    if (!dataValues.fromTo.id) {
      newErrors.from = "Please select both 'FROM (CURRENT CITY)'";
    }

    if (!inputValue || isNaN(inputValue)) {
      newErrors.income = "Please enter a valid pre-tax household income.";
    }

    return newErrors;
  };
  const handleSelectFromToData = (option) =>
    setDataValues({ ...dataValues, fromTo: option });

  const { data: movingToData, loading: loadingTo } = useFetchPlaces(1);
  const { data: fromToData, loading: loadingFrom } = useFetchPlaces(2);
  const {
    executeFetch: getAveragePriceComparison,
    data: averagePriceData,
    loading: loadingAverage,
  } = useApiData({
    url: api.getAveragePriceComparison,
    autoFetch: false,
    params: {
      indexType: 1,
      toPlace: 589,
      fromPlace: dataValues.fromTo.id,
    },
  });
  const {
    executeFetch: getExpenditureCategoryComparison,
    data: expenditureCategoriesData,
    loading: loadingExpenditure,
  } = useApiData({
    url: api.getExpenditureCategoryComparison,
    params: {
      indexType: 1,
      toPlace: 589,
      fromPlace: dataValues.fromTo.id,
    },
    autoFetch: false,
  });
  const { executeFetch: getSalaryComparison, data: salaryComparisonData } =
    useApiData({
      url: api.getSalaryComparison,
      params: {
        indexType: 1,
        fromPlaceId: dataValues.fromTo.id,
        toPlaceId: 589,
        currentBaseSalary: inputValue,
      },
      autoFetch: false,
    });

  useEffect(() => {
    const { filter } = UseFilterByCategory({
      data: averagePriceData?.dataSet?.Table1,
      name: "Expenditure_Name",
    });
    const data = filter(averageList);
    if (data) {
      setAverageList(data);
    }
  }, [averagePriceData]);

  useEffect(() => {
    const { filter } = UseFilterByCategory({
      data: expenditureCategoriesData?.dataSet?.Table1,
      name: "Category",
    });
    const data = filter(expenditureList);
    if (data) {
      setExpenditureList(data);
    }
  }, [expenditureCategoriesData]);

  const movingToOptions = mapToOptions(movingToData);
  const fromToOptions = mapToOptions(fromToData);

  useEffect(() => {
    if (expenditureList && averageList) {
      const updatedDetailsList = Object.entries(averageList).map(
        ([expenditureName, items]) => {
          const adjustedExpenditureName =
            expenditureName === "Grocery" ? "Food" : expenditureName;
          const configItem = config.find(
            (item) => item.name === adjustedExpenditureName
          );
          const percentageItem = expenditureList[expenditureName];
          return {
            data: items,
            config: {
              ...configItem,
              name: adjustedExpenditureName,
            },
            percentage: percentageItem ? [percentageItem] : [],
          };
        }
      );
      UseMoveItemInArray(updatedDetailsList, "Food", 3);
      UseMoveItemInArray(
        updatedDetailsList,
        "Healthcare",
        updatedDetailsList?.length
      );
      setDetails(updatedDetailsList);
    }
  }, [expenditureList, averageList, calculate]);
  useEffect(() => {
    if (salaryComparisonData?.comparativeSalary) {
      setComparativeSalary(salaryComparisonData?.comparativeSalary);
      const percentage = calculateCostOfLivingPercentage(
        salaryComparisonData?.comparativeSalary,
        inputValue
      );
      setCostOfLivingPercentage(percentage);
    }
  }, [expenditureList, averageList, calculate, salaryComparisonData]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }
    setNames([dataValues.movingTo, dataValues.fromTo])
    getSalaryComparison();
    getAveragePriceComparison();
    getExpenditureCategoryComparison();
    setCalculate((prevCalculate) => !prevCalculate);
  };

  const handleInput = (e) => {
    const valid = e.target.value.replace(/[^0-9.]/g, "");
    setInputValue(valid);
  };
  return (
    <>
      <SearchBox
        fromToOptions={fromToOptions}
        movingToOptions={movingToOptions}
        inputValue={inputValue}
        onSubmit={handleSubmit}
        handleInput={handleInput}
        handleSelectFromToData={handleSelectFromToData}
        errors={errors}
        loadingFrom={loadingFrom}
        loadingTo={loadingTo}
      />
      {loadingAverage || loadingExpenditure ? (
        <Spinner />
      ) : (
        <DetailsBox names={names} details={details}>
          <SpendingPowerBox
            percent={costOfLivingPercentage}
            price={comparativeSalary}
            state={names[0]?.name}
          />
        </DetailsBox>
      )}
    </>
  );
};
