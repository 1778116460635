const removeDollarSign = (value) => {
  if (typeof value === "string") {
    return value.replace(/[$,]/g, "").replace("-", "");
  }
  return value;
};

export const calculateCostOfLivingPercentage = (income, comparativeSalary) => {
  const cleanedIncome = Number(removeDollarSign(income));
  const cleanedComparativeSalary = Number(removeDollarSign(comparativeSalary));
  const incomeDifference = cleanedIncome - cleanedComparativeSalary;
  const averageValue = (cleanedIncome + cleanedComparativeSalary) / 2;
  const percentage = (incomeDifference / averageValue) * 100;

  return percentage?.toFixed(1);
};
