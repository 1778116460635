import React from "react";

export const TransportationIcon = () => {
  return (
    <svg
      width="52"
      height="44"
      viewBox="0 0 52 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.444 15.0543H11.7974C11.2492 15.0543 10.7487 14.8517 10.415 14.487C10.2481 14.3099 10.1294 14.1036 10.0676 13.8829C10.0058 13.6621 10.0024 13.4325 10.0575 13.2105L12.0119 5.26799C12.6554 2.65426 14.0615 0 19.5432 0H31.6982C37.2037 0 38.6099 2.67452 39.2295 5.26799L41.1839 13.2105C41.303 13.6562 41.16 14.1223 40.8264 14.487C40.4927 14.8517 39.9922 15.0543 39.444 15.0543ZM13.99 12.0151H37.2275L35.726 5.91636C35.2494 3.97125 34.7012 3.05948 31.6744 3.05948H19.5194C16.5164 3.05948 15.9444 3.97125 15.4677 5.91636L13.99 12.0151Z"
        fill="white"
      />
      <path
        d="M39.368 31.202H36.1266C32.9806 31.202 32.3371 29.5406 31.9796 28.6491L31.6221 27.7576C31.2408 26.785 31.2408 26.785 30.0253 26.785H21.1831C19.9676 26.785 19.9676 26.8053 19.5863 27.7576L19.2288 28.6491C18.8713 29.5406 18.2278 31.202 15.0818 31.202H11.8405C10.339 31.202 8.88512 30.655 7.88412 29.723C6.90696 28.8112 6.40646 27.5752 6.54946 26.3393L7.52662 17.404C7.83646 14.6079 8.90896 12.0144 14.5813 12.0144H36.6271C42.2995 12.0144 43.372 14.6079 43.6818 17.404L44.659 26.3596C44.802 27.5955 44.3015 28.8315 43.3243 29.7432C42.3233 30.6752 40.8695 31.202 39.368 31.202ZM35.5785 28.1426C35.6261 28.1426 35.8168 28.1628 36.1266 28.1628H39.368C39.6036 28.1659 39.8374 28.1277 40.0549 28.0506C40.2723 27.9735 40.4688 27.8592 40.632 27.7147C40.7953 27.5703 40.9217 27.3988 41.0036 27.2109C41.0854 27.0231 41.1209 26.823 41.1078 26.6229L40.1306 17.6674C39.94 15.8641 39.8446 15.0334 36.651 15.0334H14.6051C11.4115 15.0334 11.3161 15.8641 11.1255 17.6674L10.1483 26.6229C10.1006 27.0079 10.2436 27.3929 10.5773 27.6765C10.911 27.9805 11.3876 28.1628 11.8881 28.1628H15.1295C15.6061 28.1628 15.7491 28.102 15.773 28.102C15.7491 28.102 15.8206 27.8589 15.8921 27.6968L16.2496 26.8053C16.7025 25.6707 17.4413 23.7458 21.2308 23.7458H30.073C33.5288 23.7458 34.4106 25.1844 35.0303 26.7648L35.3878 27.6765C35.4355 27.8589 35.507 28.0615 35.5785 28.1426ZM11.7952 10.6366H10.0553C9.07817 10.6366 8.26784 9.94774 8.26784 9.11702C8.26784 8.2863 9.07817 7.59741 10.0553 7.59741H11.7952C12.7723 7.59741 13.5827 8.2863 13.5827 9.11702C13.5827 9.94774 12.7723 10.6366 11.7952 10.6366ZM41.1841 10.6366H39.4442C38.4671 10.6366 37.6567 9.94774 37.6567 9.11702C37.6567 8.2863 38.4671 7.59741 39.4442 7.59741H41.1841C42.1612 7.59741 42.9716 8.2863 42.9716 9.11702C42.9716 9.94774 42.1612 10.6366 41.1841 10.6366Z"
        fill="white"
      />
      <path
        d="M20.4253 20.9262H15.2296C14.2525 20.9262 13.4421 20.2373 13.4421 19.4066C13.4421 18.5759 14.2525 17.887 15.2296 17.887H20.4253C20.8994 17.887 21.354 18.0471 21.6892 18.332C22.0245 18.617 22.2128 19.0035 22.2128 19.4066C22.2128 19.8096 22.0245 20.1961 21.6892 20.4811C21.354 20.7661 20.8994 20.9262 20.4253 20.9262ZM35.9932 20.9262H30.7976C29.8204 20.9262 29.0101 20.2373 29.0101 19.4066C29.0101 18.5759 29.8204 17.887 30.7976 17.887H35.9932C36.9704 17.887 37.7807 18.5759 37.7807 19.4066C37.7807 20.2373 36.9704 20.9262 35.9932 20.9262ZM25.621 35.4577C24.6438 35.4577 23.8335 34.7688 23.8335 33.9381V31.912C23.8335 31.0812 24.6438 30.3924 25.621 30.3924C26.5981 30.3924 27.4085 31.0812 27.4085 31.912V33.9381C27.4085 34.7688 26.5981 35.4577 25.621 35.4577ZM25.621 43.5623C24.6438 43.5623 23.8335 42.8734 23.8335 42.0427V40.0166C23.8335 39.1858 24.6438 38.4969 25.621 38.4969C26.5981 38.4969 27.4085 39.1858 27.4085 40.0166V42.0427C27.4085 42.8734 26.5981 43.5623 25.621 43.5623ZM1.79239 43.5664C1.64939 43.5664 1.50639 43.5461 1.36339 43.5258C0.410054 43.3232 -0.185779 42.4925 0.0525539 41.6821L2.43589 33.5775C2.49222 33.3832 2.59301 33.2003 2.7325 33.0392C2.87198 32.8781 3.04743 32.7419 3.24882 32.6384C3.45022 32.5349 3.67361 32.4662 3.90626 32.4361C4.13891 32.406 4.37624 32.4152 4.60472 32.4631C4.8332 32.511 5.04834 32.5967 5.23787 32.7152C5.4274 32.8338 5.5876 32.983 5.70932 33.1542C5.83104 33.3254 5.9119 33.5153 5.94729 33.7131C5.98267 33.9109 5.97189 34.1126 5.91555 34.3069L3.53222 42.4115C3.31772 43.1004 2.60272 43.5664 1.79239 43.5664ZM49.4519 43.5603C48.6416 43.5603 47.9266 43.0943 47.7121 42.4054L45.3287 34.3008C45.0904 33.4903 45.6624 32.6596 46.6396 32.457C47.5929 32.2544 48.5701 32.7407 48.8084 33.5714L51.1917 41.676C51.2579 41.9002 51.2629 42.1343 51.2062 42.3605C51.1496 42.5866 51.0328 42.7987 50.8649 42.9805C50.697 43.1624 50.4824 43.3092 50.2375 43.4097C49.9926 43.5102 49.7238 43.5617 49.4519 43.5603Z"
        fill="white"
      />
    </svg>
  );
};