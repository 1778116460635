import React from "react";

export const FoodIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4113 38.4584H16.3363C14.1913 38.4584 12.133 37.5484 10.6813 35.9667C9.96128 35.1826 9.4107 34.2583 9.06399 33.2518C8.71729 32.2452 8.58192 31.178 8.66634 30.1167L10.4647 8.53671C10.5297 7.86504 10.2913 7.21504 9.83634 6.71671C9.38134 6.21837 8.75301 5.95837 8.08134 5.95837H4.33301C3.44467 5.95837 2.70801 5.22171 2.70801 4.33337C2.70801 3.44504 3.44467 2.70837 4.33301 2.70837H8.10301C9.68467 2.70837 11.1797 3.38004 12.2413 4.52837C12.8263 5.17837 13.2597 5.93671 13.498 6.78171H40.5597C42.748 6.78171 44.763 7.64837 46.2363 9.20837C47.688 10.79 48.4247 12.8484 48.2513 15.0367L47.0813 31.2867C46.843 35.2517 43.3763 38.4584 39.4113 38.4584ZM13.6063 10.01L11.9163 30.3767C11.808 31.6334 12.2197 32.825 13.0647 33.7567C13.9097 34.6884 15.0797 35.1867 16.3363 35.1867H39.4113C41.6647 35.1867 43.7013 33.28 43.8747 31.0267L45.0447 14.7767C45.0961 14.1624 45.0185 13.5441 44.8167 12.9616C44.615 12.3791 44.2936 11.8452 43.8733 11.3942C43.453 10.9433 42.9429 10.5852 42.376 10.3431C41.8091 10.101 41.1978 9.98018 40.5813 9.98837H13.6063V10.01ZM35.208 49.2917C32.8247 49.2917 30.8747 47.3417 30.8747 44.9584C30.8747 42.575 32.8247 40.625 35.208 40.625C37.5913 40.625 39.5413 42.575 39.5413 44.9584C39.5413 47.3417 37.5913 49.2917 35.208 49.2917ZM35.208 43.875C34.6013 43.875 34.1247 44.3517 34.1247 44.9584C34.1247 45.565 34.6013 46.0417 35.208 46.0417C35.8147 46.0417 36.2913 45.565 36.2913 44.9584C36.2913 44.3517 35.8147 43.875 35.208 43.875ZM17.8747 49.2917C15.4913 49.2917 13.5413 47.3417 13.5413 44.9584C13.5413 42.575 15.4913 40.625 17.8747 40.625C20.258 40.625 22.208 42.575 22.208 44.9584C22.208 47.3417 20.258 49.2917 17.8747 49.2917ZM17.8747 43.875C17.268 43.875 16.7913 44.3517 16.7913 44.9584C16.7913 45.565 17.268 46.0417 17.8747 46.0417C18.4813 46.0417 18.958 45.565 18.958 44.9584C18.958 44.3517 18.4813 43.875 17.8747 43.875Z"
        fill="white"
      />
      <path
        d="M45.5 18.9584H19.5C18.6117 18.9584 17.875 18.2217 17.875 17.3334C17.875 16.445 18.6117 15.7084 19.5 15.7084H45.5C46.3883 15.7084 47.125 16.445 47.125 17.3334C47.125 18.2217 46.3883 18.9584 45.5 18.9584Z"
        fill="white"
      />
    </svg>
  );
};
