export const UseFilterByCategory = ({ data, name }) => {
  const filter = () => {
    const newCategories = {};
    data?.map((item) => {
      const expenditureName = item[name];
      if (!newCategories[expenditureName]) {
        newCategories[expenditureName] = [];
      }

      newCategories[expenditureName].push(item);
    });

    return newCategories;
  };

  return {
    filter,
  };
};
